var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background"},[_c('div',{staticClass:"z_nav"},[_c('div',{staticClass:"typeArea ul"},[_c('div',{staticClass:"a",on:{"click":function($event){return _vm.tomyCollectionarticles()}}},[_vm._v("我的收藏")]),_c('div',{staticClass:"a active",on:{"click":function($event){return _vm.tomyRecentInformation()}}},[_vm._v("近期浏览")])])]),_c('div',{staticClass:"typeArea content"},[_c('div',{staticClass:"z_index"},[_c('div',{staticClass:"left"},[(_vm.Historicalrecordlist.length > 0)?_c('div',{staticClass:"content-top"},[_c('div',{staticClass:"content-item new-project"},[_c('div',{staticClass:"content-main"},[_c('table',{staticStyle:{"display":"none"}},[_vm._m(0),_c('tbody',_vm._l((_vm.Historicalrecordlist),function(item){return _c('tr',{key:item.id},[_c('td',{attrs:{"title":item.title}},[_vm._v(" "+_vm._s(item.title)+" "),(item.title == null)?_c('span',[_vm._v("——")]):_vm._e()]),_c('td',{attrs:{"title":item.dateformate}},[_vm._v(_vm._s(item.dateformate))])])}),0)]),_vm._l((_vm.Historicalrecordlist.slice(0, _vm.shuliang)),function(item){return _c('div',{key:item.id,staticClass:"content-item1"},[_c('div',{staticClass:"item-content"},[_c('div',{staticClass:"content-title",on:{"click":function($event){return _vm.toReportingGuideDetail(item)}}},[(item.HITS>700)?_c('div',{staticClass:"re"},[_vm._v(" 热 ")]):_vm._e(),_c('div',{staticClass:"title-text"},[_vm._v(_vm._s(item.PROJECT_NAME))])]),_c('div',{staticClass:"content-info"},[_c('div',{staticClass:"info-item long"},[_c('div',{staticClass:"item-describe"},[_vm._v("发布机构：")]),_c('div',{staticClass:"item-info"},[(
                            item.PROJECT_GOVERNMENT == null ||
                            item.PROJECT_GOVERNMENT == ''
                          )?_c('span',[_vm._v("——")]):_c('span',{staticClass:"fbjg"},[_vm._v(_vm._s(item.PROJECT_GOVERNMENT))])])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"item-describe"},[_vm._v("资助力度：")]),_c('div',{staticClass:"item-info"},[(
                            item.PROJECT_FUNDS == '' ||
                            item.PROJECT_FUNDS == null
                          )?_c('span',[_vm._v("——")]):_c('span',[_vm._v(_vm._s(item.PROJECT_FUNDS)+"万元")])])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"item-describe"},[_vm._v("发布时间：")]),_c('div',{staticClass:"item-info"},[_vm._v(_vm._s(item.PROJECT_DATE))])]),_c('div',{staticClass:"info-item long"},[_c('div',{staticClass:"item-describe"},[_vm._v("资助范围：")]),_c('div',{staticClass:"item-info"},[(item.PROJECT_GOVERNMENT_PRO)?_c('span',[_vm._v(_vm._s(item.PROJECT_GOVERNMENT_PRO))]):_c('span',[_vm._v("——")])])]),_c('div',{staticClass:"info-item"},[_c('div',{staticClass:"item-describe"},[_vm._v("截至时间：")]),_c('div',{staticClass:"item-info"},[_vm._v(_vm._s(item.PROJECT_DATE_END))])])]),_c('div',{staticClass:"content-tag"},_vm._l((item.PROJECT_INDUSTRY),function(ite,index){return _c('div',{key:index,staticClass:"tag-item",on:{"mouseenter":function($event){return _vm.tagmouseenter($event)},"mouseleave":function($event){return _vm.tagmouseleave($event)}}},[_vm._v(" "+_vm._s(ite)+" ")])}),0)])])})],2),_c('div',{staticClass:"seemore",on:{"click":_vm.getMoreNews}},[_vm._v(" 查看更多内容 ")])])]):_c('div',{staticClass:"content-top nocollection",staticStyle:{"flex-direction":"column"}},[_c('img',{staticStyle:{"max-width":"88%"},attrs:{"src":require("../../../assets/image/我的订阅_slices/未订阅.png"),"alt":""}}),_c('div',{staticStyle:{"font-size":"20px","color":"#282828","line-height":"1.5","margin":"110px 0","text-align":"center"}},[_vm._v(" 您还没有收藏任何内容 ")])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"z_xgkt"},[_c('div',{staticClass:"title"},[_vm._v("推荐阅读")]),_c('ul',_vm._l((_vm.tonghang.slice(0, 5)),function(item,index){return _c('Li',{key:index,on:{"click":function($event){return _vm.toReportingGuideDetail(item)}}},[_c('h1',[_vm._v(_vm._s(item.PROJECT_NAME))]),_c('h2',[_vm._v(_vm._s(item.PROJECT_DATE_END))])])}),1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',[_vm._v("记录标题")]),_c('td',[_vm._v("查看时间")])])])
}]

export { render, staticRenderFns }